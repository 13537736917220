<script setup lang="ts">
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import {computed, onMounted, Ref, ref, watch} from 'vue';
import backendCall from "@/services/AxiosService";
import {useToast} from "primevue/usetoast";
import {useReferenceStore} from "@/stores/referenceStore";

const props = defineProps({
  gensetNumber: String,
})

const emit = defineEmits(['refreshPretrips']);

const toast = useToast();
const referenceStore = useReferenceStore();

onMounted(() => {
  referenceStore.fetchReferenceData();
})

interface PretripData {
  gensetNumber: string | null,
  pretripPass: boolean | undefined,
  g1Hours: number | null,
  g2Hours: number | null,
  defAdded: number | null,
  oilAdded: number | null,
  coolantAdded: number | null,
  notes: string | null,
}

const gensetObject: Ref<{ genset_number: string, has_def: boolean }> = ref({genset_number: '', has_def: false});


const pretripData: Ref<PretripData> = ref({
  gensetNumber: props.gensetNumber || '',
  pretripPass: undefined,
  g1Hours: null,
  g2Hours: null,
  defAdded: 0,
  oilAdded: 0,
  coolantAdded: 0,
  notes: null,
})



watch(
    () => props.gensetNumber,
    (newVal) => {
      if (newVal) {
        gensetObject.value.genset_number = newVal!;
        pretripData.value.gensetNumber = newVal!;
      }
    }, {immediate: true,once: true}
);

watch(gensetObject, (newVal) => {
  pretripData.value.gensetNumber = newVal?.genset_number || '';
}, {immediate: true}
);

const visible = defineModel('visible');

const isSubmitting = ref(false);
const submitPretrip = async () => {
  try {
    isSubmitting.value = true;
    await backendCall.post(`power-pack/pretrip`, pretripData.value);
    toast.add({severity: 'success', summary: 'Success', detail: 'Pretrip Submitted', life: 5000});
    visible.value = false;
    emit("refreshPretrips");
  } catch (e) {
    toast.add({severity: 'error', summary: 'Error', detail: 'Error Submitting Pretrip.', life: 5000});
  } finally {
    isSubmitting.value = false;
  }
}

const allGensets = referenceStore?.cnPowerPacks;
const gensetSuggestions = ref([]);
const searchGensets = (event: { query: string }) => {
  const query = event.query.trim().toUpperCase();
  gensetSuggestions.value = allGensets.filter((genset: {
    genset_number: string,
    has_def: boolean
  }) => genset.genset_number.includes(query));
};

const allFieldsValid = computed(() => {
  return !!(pretripData.value.gensetNumber && pretripData.value.pretripPass !== undefined && pretripData.value.g1Hours && pretripData.value.g2Hours && !isSubmitting.value);

})

</script>

<template>
  <Dialog v-model:visible="visible" append-to="body" modal :breakpoints="{ '960px': '75vw', '640px': '96vw' }"
          :style="{ width: '32vw' }" header="Edit Payment Method" :draggable="false" :resizable="false">
    <template #header>
      <div class="flex flex-col gap-2">
        <h1 class="m-0 text-surface-900 dark:text-surface-0 font-semibold text-xl leading-normal">New Powerpack
          Pretrip</h1>
      </div>
    </template>
    <form class="flex flex-col gap-4 mt-4">
      <div class="flex gap-4">
        <div class="w-full">
          <label for="genset-number" class="block mb-1 text-color text-base">Genset Number</label>
          <AutoComplete v-model="gensetObject" :suggestions="gensetSuggestions" :disabled="!!props.gensetNumber"
                        @complete="searchGensets"
                        :force-selection="true" option-label="genset_number" id="genset-number" name="genset-number"
                        type="text" class="w-3/4" fluid/>
        </div>
        <div class="w-full">
          <label for="pretrip-pass" class="block mb-4 text-color text-base">Pretrip Pass</label>
          <span class="mr-2">Fail</span>
          <RadioButton class="mr-4" v-model="pretripData.pretripPass" :value="false"></RadioButton>
          <span class="mr-2">Pass</span>
          <RadioButton v-model="pretripData.pretripPass" :value="true"></RadioButton>

        </div>
      </div>

      <div class="flex gap-4">
        <div class="w-full">
          <label for="g1-hours" class="block mb-1 text-color text-base">G1 Hours</label>
          <InputGroup>
            <InputNumber v-model="pretripData.g1Hours" id="g1-hours" :min-fraction-digits="1" name="g1-hours"
                         type="text" class="w-full"/>
            <InputGroupAddon>
              HRS
            </InputGroupAddon>
          </InputGroup>
        </div>
        <div class="w-full">
          <label for="g2-hours" class="block mb-1 text-color text-base">G2 Hours</label>
          <InputGroup>
            <InputNumber v-model="pretripData.g2Hours" id="g2-hours" :min-fraction-digits="1" name="g2-hours"
                         type="text" class="w-full"/>
            <InputGroupAddon>
              HRS
            </InputGroupAddon>
          </InputGroup>
        </div>
      </div>
      <div class="flex gap-4">
        <div class="w-full">
          <label for="def-added" class="block mb-1 text-color text-base">DEF Added</label>
          <InputGroup>
            <InputNumber id="def-added" v-model="pretripData.defAdded" :max="450" name="def-added" type="text"
                         class="w-full"/>
            <InputGroupAddon>
              L
            </InputGroupAddon>
          </InputGroup>
        </div>
        <div class="w-full">
          <label for="oil-added" class="block mb-1 text-color text-base">Oil Added</label>
          <InputGroup>
            <InputNumber id="oil-added" v-model="pretripData.oilAdded" :max="15" name="oil-added" type="text"
                         class="w-full"/>
            <InputGroupAddon>
              L
            </InputGroupAddon>
          </InputGroup>
        </div>
        <div class="w-full">
          <label for="coolant-added" class="block mb-1 text-color text-base">Coolant Added</label>
          <InputGroup>
            <InputNumber id="def-added" v-model="pretripData.coolantAdded" :max="15" name="coolant-added" type="text"
                         class="w-full"/>
            <InputGroupAddon>
              L
            </InputGroupAddon>
          </InputGroup>
        </div>
      </div>
      <div class="flex gap-4">
        <div class="w-full">
          <label for="notes" class="block mb-1 text-color text-base">Notes</label>
          <Textarea v-model="pretripData.notes" class="w-full" id="notes" name="notes" type="text"/>
        </div>
      </div>
    </form>
    <!--    <div class="col-span-12 lg:col-span-3 p-3">-->
    <!--      <div class="bg-surface-0 dark:bg-surface-900 rounded-border shadow">-->
    <!--        <div class="border-b border-surface my-4 py-2">-->
    <!--          <div class="flex justify-between mb-4">-->
    <!--            <span class="text-surface-900 dark:text-surface-0">DEF</span>-->
    <!--            <span class="text-surface-900 dark:text-surface-0">{{ pretripData.defAdded ?? 0 }}L</span>-->
    <!--          </div>-->
    <!--          <div class="flex justify-between mb-4">-->
    <!--            <span class="text-surface-900 dark:text-surface-0">Oil</span>-->
    <!--            <span class="text-surface-900 dark:text-surface-0">{{ pretripData.oilAdded ?? 0 }}L</span>-->
    <!--          </div>-->
    <!--          <div class="flex justify-between mb-4">-->
    <!--            <span class="text-surface-900 dark:text-surface-0">Coolant</span>-->
    <!--            <span class="text-surface-900 dark:text-surface-0">{{ pretripData.coolantAdded ?? 0 }}L</span>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <template #footer>
      <div class="w-full flex gap-4 justify-end border-t border-surface pt-8">
        <Button label="Cancel" text @click="visible = false"/>
        <Button label="Submit" :disabled="!allFieldsValid" rounded @click="submitPretrip"/>
      </div>
    </template>
  </Dialog>
</template>

