import {defineStore} from 'pinia';
import backendCall from "../services/AxiosService";


export const useReferenceStore = defineStore({
    id: 'referenceStore', state: () => ({
        tracks: [],
        alarms: [],
        userRoles: [],
        cnPowerPacks: [],
        clipOns: [],
    }), actions: {
        async fetchReferenceData() {
            try {
                const response = await backendCall.get('reference-data');
                const referenceDataResponse = response.data.data;

                this.tracks = referenceDataResponse.tracks;
                this.alarms = referenceDataResponse.alarms;
                this.userRoles = referenceDataResponse.availableUserRoles;
                this.cnPowerPacks = referenceDataResponse.cnPowerPacks;
                this.clipOns = referenceDataResponse.clipOns;

                return response;
            } catch (error) {
                console.error('There was a problem with the axios operation:', error);
                throw error;
            }
        }
    },

});
