import {createRouter, createWebHistory} from 'vue-router'
import {useAuthStore} from "@/stores/authStore";
import Pretrips from "@/views/powerpacks/Pretrips.vue";
import GensetInventory from "@/views/yardcheck/GensetInventory.vue";
const DomesticConsistParser = () => import("@/views/trains/DomesticConsistParser.vue");
const ConsistParser = () => import("@/views/trains/ConsistParser.vue");
const TrainLineup = () => import("../components/Trainlineup.vue");
// import NewTrain from "@/components/trains/NewTrain.vue";
// import TrainEtaUpdate from "@/components/trains/TrainEtaUpdate.vue";
// import TrainConsist from "@/components/trains/TrainConsist.vue";
// import SearchCN from "@/components/u
// nit-lookup/SearchCN.vue";
// import WaybillSearch from "@/components/waybill/WaybillSearch.vue";
// import UnitCardContainer from "@/components/UnitCardContainer.vue";
const loginPage = () => import('../views/auth/Login.vue');
const cnUnitQuery = () => import("../views/CnUnitQuery.vue");
const ConsistPage = () => import('../views/trains/ConsistPage.vue');
const MainLayout = () => import("../layout/MainLayout.vue");
const NotFoundPage = () => import('../views/errors/NotFoundPage.vue');
const RequestList = () => import('../views/requests/CurrentRequestList.vue');
const FuelLedger = () => import('../views/fuel/FuelLedger.vue');
const UserProfile = () => import('../views/user/UserProfile.vue');
const AdminPanel = () => import('../views/admin/AdminPanel.vue');
const StatisticsPage = () => import('../views/reports/StatisticsPage.vue');
const clipDashboard = () => import("../views/clipons/ClipDashboard.vue")
const Calendar = () => import('../views/Calendar.vue');
const TrainLineupDataView = () => import('../components/TrainLineupDataView.vue');
const AddBulkElectrics = () => import('../views/trains/AddBulkElectrics.vue');
const MySubscriptions = () => import('../views/user/MySubscriptions.vue');
const FieldTrainCheck = () => import('../views/trains/FieldTrainCheck.vue');
const TrainTime = () => import("../views/billing/TrainTime.vue");
const TrainHistory = () => import('../views/trains/History.vue');
const PasteableLineup = () => import('../views/trains/Pasteable-Lineup.vue');
const YardCheckDashboard = () => import('../views/yardcheck/YardCheckDashboard.vue');
const PerformYardCheck = () => import('../views/yardcheck/PerformYardCheck.vue');
const ViewYardCheck = () => import('../views/yardcheck/ViewYardCheck.vue');
const RequestPrintConfiguration = () => import('../views/requests/RequestPrintConfiguration.vue');
const JobsView = () => import('../views/billing/JobsView.vue');
const WhosIn = () => import('../views/staffing/WhosIn.vue');
const StatusSummary = () => import('../views/fleet/StatusSummary.vue');
const MyTimes = () => import('../views/user/MyTimes.vue')
const Forbidden = () => import('../views/errors/Forbidden.vue');
const RequestHistoryList = () => import('../views/requests/RequestHistoryList.vue');


const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            component: MainLayout,
            meta: {requiresAuth: true},
            children: [
                {path: '', component: TrainLineupDataView},
                {path: 'trains', name: 'TrainLineup',component: TrainLineupDataView},
                {path: 'trains/history', component: TrainHistory},
                {path: 'trains/:trainid', component: ConsistPage, props: true},
                {path: 'trains/:trainid/time', component: TrainTime, props: true},
                {path: 'trains/:trainid/consist-parser', name: 'ConsistParser', component: ConsistParser, props: true},
                {path: 'trains/:trainid/domestic-consist-parser', name: 'DomesticConsistParser', component: DomesticConsistParser, props: true},
                {path: 'trains/old', component: TrainLineup},
                {path: 'unitquery', name: 'unitQueryPage', component: cnUnitQuery},
                {path: 'requests', component: RequestList},
                {path: 'requests/history', component: RequestHistoryList},
                {path: 'requests/print-setup', name: 'requestPrintConfiguration', component: RequestPrintConfiguration},
                {path: 'fuel', name: 'FuelLedger', component: FuelLedger},
                {path: 'user/profile', name: 'userProfile', component: UserProfile},
                {path: 'admin', name: 'adminPanel', component: AdminPanel},
                {path: '/reports/statistics', name: 'statisticsReport', component: StatisticsPage},
                {path: '/clipons', name: 'cliponDashboard', component: clipDashboard},
                {path: '/calendar', name: 'calendarComponent', component: Calendar},
                {path: 'trains/:trainid/bulkelectrics', name: 'BulkElectrics', component: AddBulkElectrics},
                // {path: 'trains/:trainid/bulkelectrics2', name: 'BulkElectrics2', component: AddBulkElectrics2},
                {path: 'user/subscriptions', name: 'UserSubscriptions', component: MySubscriptions},
                {path: 'user/times', name: 'MyTimes', component: MyTimes},
                {path: 'billing/jobs', name: 'JobView', component: JobsView},
                {path: 'trains/lineup/pasteable', name: 'PasteableLineup', component: PasteableLineup},
                {path: 'yard-check', name: 'YardCheckDashboard', component: YardCheckDashboard},
                {path: 'yard-check/:yardCheckId/perform', name: 'PerformYardCheck', component: PerformYardCheck, props: true},
                {path: 'yard-check/:yardCheckId', name: 'ViewYardCheck', component: ViewYardCheck, props: true},
                {path: 'staffing/whos-in', name: 'WhosIn', component: WhosIn},
                {path: 'forbidden', name: 'Forbidden', component: Forbidden},
                {path: 'fleet/summary', name: 'FleetSummary', component: StatusSummary},
                {path: 'powerpack/pretrips', name: 'PowerpackPretrips', component: Pretrips},
                {path: 'powerpack/inventory', name: 'GensetInventory', component: GensetInventory, props: true},
                // {path: 'consist-parser', name: 'ConsistParser', component: ConsistParser},




                // More authenticated routes can be nested here
            ]
        },
        {
            // The login page is outside the MainLayout
            name: 'login',
            path: '/login',
            component: loginPage,
            meta: {requiresUnauthenticated: true}
        },
        {path: '/trains/:trainid/fieldui', component: FieldTrainCheck, props: true, meta: {requiresAuth: true}},
        {path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFoundPage},
    ]
})

router.beforeEach(async (to, from, next) => {
    const authStore = useAuthStore();
//todo check if we can just check this in main.ts or app.vue. I believe we can to avoid having to check auth status from the backend on every navigation.
    await authStore.checkAuthStatus();
    if (to.meta.requiresAuth) {
        if (authStore.user) {
            next();
        } else {
            next('/login');
        }
    } else if (to.meta.requiresUnauthenticated && authStore.isAuthenticated) {
        next('/');
    } else {
        next();
    }
});


export default router
